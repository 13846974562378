import React from 'react'

import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'
import BackgroundAnimation from '../../components/BackgroundAnimation'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div　className="full-width-image margin-top-0">
          <h1
            className="has-text-weight-bold is-size-1 page-header-tithas-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen page-header-title"
          >
            Latest Stories
          </h1>
        </div>
        <section className="section">
          <div className="container">
            <div className="content">
              <BlogRoll />
            </div>
          </div>
        </section>
        <BackgroundAnimation></BackgroundAnimation>
      </Layout>
    )
  }
}
