import React from 'react'

const BackgroundAnimation = class extends React.Component {
  render() {
    return (
        <ul class="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
    )
  }
}

export default BackgroundAnimation
